import React from 'react'


import './LoginForm.css'

const LoginForm = ({
    isSubmitted,
    Loading,
    renderErrorMessage,
    errorMessage,
    formik
}) => {
    return (
            <div className="row gradient-form m-0 gradient-custom-2 m-1">
                <div className="col-12 col-sm-6 w-50 p-0">
                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                            <h4 className="mb-4">We are more than just a company</h4>
                            <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 w-50 p-0 m-0">
                    <div className=" d-flex flex-column justify-content-center align-items-center h-100" >
                        <div className="card" 
                            style={{ 
                                width:300,
                                maxWidth:"100%" 
                            }}>
                            {/* <h5 className="card-header">Login</h5> */}
                            <div className="text-center">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                    style={{
                                        width: 185
                                    }} alt="logo" />
                                <h4 className="mt-1 mb-5 pb-1">We are The Lotus Team</h4>
                                <p>Please login to your account</p>
                            </div>                   

                            <div className="card-body">
                                {isSubmitted ? (
                                    <div className="centered text-center w-50">
                                        <Loading className="w-25"/>
                                    </div>
                                ) : ''}
                                {renderErrorMessage(errorMessage)}
                                <form
                                    onSubmit={formik.handleSubmit}
                                >
                                    <div className="input-group form-group mb-3">
                                        <label className="form-control text-center w-25 text-primary"><i
                                            className="bi bi-person"></i></label>
                                        <input type="text" className="form-control w-75 text-primary" placeholder="Email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.email && formik.touched.email &&
                                            renderErrorMessage(formik.errors.email)}
                                    </div>
                                    <div className="input-group form-group mb-3">
                                        <label className="form-control text-center w-25 text-primary"><i
                                            className="bi bi-lock"></i></label>
                                        <input type="password" className="form-control w-75 text-primary" placeholder="Password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.password && formik.touched.password &&
                                            renderErrorMessage(formik.errors.password)}
                                    </div>
                                    <div className="mb-3 text-end">
                                        <button type="submit"
                                                className="btn btn-danger mr-1">
                                            <i className="bi bi-box-arrow-in-right"></i> Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>          
        </div>
    )
}

export default LoginForm
