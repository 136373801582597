import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"
// import 'react-datepicker/dist/react-datepicker.min.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './index.css';

// new change

import {BrowserRouter} from 'react-router-dom';
import AuthProvider from './context/AuthProvider';

// call again
// Auto check project on 10s
// function emitCheckProject(page) {
//   setInterval(() => {
//       window.dispatchEvent(
//           new CustomEvent(`pushNotification-${page}`, {
//               detail: `Check notification ${page}`
//           })
//       )
//       // }, 300000) //300s = 5m
//       // }, 30000) //30s
//   }, 300000) //300s = 5m
// }

// emitCheckProject(1)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
      <BrowserRouter
        // basename={process.env.REACT_APP_PUBLIC_URL}
      >
        <AuthProvider>
            <App/>
        </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
