import {useContext, useEffect} from 'react'
import {useFormik} from 'formik';
import * as Yup from 'yup';
import AuthContext from '../../context/AuthContext';
import Loading from '../layouts/Loading';
import Dashboard from './Dashboard';
import Homepage from './Homepage';
import Global from '../../libs/Global';
import Layout from '../layouts/Layout';
import LoginForm from './LoginForm';

const Login = () => {
    const {
        auth,
        setAuth,
        handleSubmit,
        isSubmitted,
        errorMessage,
        setErrorMessage,
        
    } = useContext(AuthContext);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email format")
                .required("Required"),
            password: Yup.string()
                .required("Required"),
        }),
        onSubmit: values => {
            setErrorMessage('')
            const param = JSON.stringify(values, null, 2);
            handleSubmit(JSON.parse(param))
        }
    });

    // Generate JSX code for error message
    const renderErrorMessage = (message) => (
        <div className="text-danger">
            {message}
        </div>
    );

    useEffect(() => {
        //load Payroll Types to selected box
        // if (firstRender.current) {
        //     firstRender.current = false;
        //     getTokenWhatsapp()
        // }

        setAuth(
            localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : []
        )
    
        // check url for get token
        // if (location.pathname == '/connectHRM') {
        //     handleTokenHRM()
        // }

        // setTokenWhatsapp(
        //     localStorage.getItem('tokenWhatsapp') ? localStorage.getItem('tokenWhatsapp') : ''
        // )
    }, [
        // token
        // tokenWhatsapp
    ])
    
    return <Layout>
    {auth.status === 'success' ?
        Global.isSuperAdmin(auth.user.type)
            ? <Dashboard auth={auth}/>
            : <Homepage auth={auth}/>
        : <LoginForm
            isSubmitted={isSubmitted}
            Loading={Loading}
            renderErrorMessage={renderErrorMessage}
            errorMessage={errorMessage}
            formik={formik}
        />
    }
    </Layout>
}

export default Login
