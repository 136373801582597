import React, { useEffect } from 'react';
import SideBar from './SideBar';
import Header from './Header';
import { useLocation } from 'react-router-dom';

const Layout =({children}) => {
    const location = useLocation();
    useEffect(() => {
        // pageSubLinks.map((page)=>{
        //     if(page.url === location.pathname) {
        //         document.title = page.name;
        //     }
        // });
    }, [])

    // console.log(location)

    return (
        <div className="container-fluid" id="main">
            {children}
            {/* <Header/> */}
            {/* {(location.pathname == '/payroll'
            || location.pathname == '/profile' || location.pathname == '/payment')
            ? (
                <div className="row row-offcanvas row-offcanvas-left mt-0">
                    <div className="col">
                        <div className="row mt-0">
                            {children}
                        </div>
                    </div>
                </div>
            )
            : ( */}
            {/* <div className="row row-offcanvas row-offcanvas-left mt-0">
                <SideBar/>
                <div className="col-10 col-md-10 col-sm-10">
                    <div className="row mt-0">
                        {children}
                    </div>
                </div>
            </div> */}
            {/* )} */}
        </div>
    )
}

export default Layout;
