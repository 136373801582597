import clientInstance from "./clientInstance"

const UserService = {
    getUserList: async (params) => {
        return await clientInstance.get(
            '/user',
            {
                params: params,
            }
        )
    },
    getUser: async (id) => {
        return await clientInstance.get(
            '/user/' + id
        )
    },
    getUserLeader: async () => {
        return await clientInstance.get(
            '/user/create'
        )
    },
    createUser: async (params) => {
        return await clientInstance.post(
            '/user',
            params
        )
    },
    updateUser: async (id, params) => {
        return await clientInstance.put(
            '/user/' + id,
            params
        )
    },
    deleteUser: async (id) => {
        return await clientInstance.delete(
            '/user/' + id
        )
    },
    connectHRM: async (params) => {
        return await clientInstance.post(
            '/connect-hrm',
            params
        )
    },
    tokenHRM: async (params) => {
        return await clientInstance.post(
            '/token-hrm',
            params
        )
    },
    resetTokenHRM: async () => {
        return await clientInstance.put(
            '/reset-token-hrm')
    },

    makeOAuthWhatsapp: async (params) => {
        return await clientInstance.post(
            '/make-oauth',
            params
        )
    },

    resetTokenWhatsapp: async (params) => {
        return await clientInstance.post(
            '/reset-oauth',
            params
        )
    },

    getTokenWhatsapp: async (params) => {
        return await clientInstance.get(
            '/get-oauth',
            params
        )
    },
}

export default UserService
