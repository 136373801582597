import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/pages/Login';
// import Login from './components/screens/auth/Login';

// import UserManager from './components/screens/system/UserManager';
// import ConnectWhatsapp from './components/screens/auth/ConnectWhatsapp';
// import WhatsappManager from './components/screens/system/WhatsappManager';
// import Contacts from './components/screens/page/Contacts';
// import TemplateManager from './components/screens/page/TemplateManager';

// import Header from './components/layouts/Header';
// import Footer from './components/layouts/Footer';

// import ConnectHRM from './components/screens/auth/ConnectHRM';
// import ProfileManager from './components/screens/system/ProfileManager';
// import PayrollManager from './components/screens/system/PayrollManager';

// new layout
// import Dashboard from "./components/demo/Dashboard";
// import Navbar from "./components/demo/Navbar";
// import Sidebar from "./components/demo/Sidebar";

// import BankList from './components/screens/page/BankList';
// import BankCreate from './components/screens/page/BankCreate';
// import BankEdit from './components/screens/page/BankEdit';
// import BankShow from './components/screens/page/BankShow';

// import DepartmentList from './components/screens/page/DepartmentList';
// import DepartmentCreate from './components/screens/page/DepartmentCreate';
// import DepartmentEdit from './components/screens/page/DepartmentEdit';
// import DepartmentShow from './components/screens/page/DepartmentShow';

// import JobTitleList from './components/screens/page/JobTitleList';
// import JobTitleCreate from './components/screens/page/JobTitleCreate';
// import JobTitleEdit from './components/screens/page/JobTitleEdit';
// import JobTitleShow from './components/screens/page/JobTitleShow';

// import PositionList from './components/screens/page/PositionList';
// import PositionCreate from './components/screens/page/PositionCreate';
// import PositionEdit from './components/screens/page/PositionEdit';
// import PositionShow from './components/screens/page/PositionShow';
// import PaymentManager from './components/screens/system/PaymentManager';
// import ContractTypeList from './components/screens/page/ContractTypeList';
// import ContractTypeCreate from './components/screens/page/ContractTypeCreate';
// import ContractTypeEdit from './components/screens/page/ContractTypeEdit';
// import ContractTypeShow from './components/screens/page/ContractTypeShow';
// import BonusTypeList from './components/screens/page/BonusTypeList';
// import BonusTypeCreate from './components/screens/page/BonusTypeCreate';
// import BonusTypeEdit from './components/screens/page/BonusTypeEdit';
// import BonusTypeShow from './components/screens/page/BonusTypeShow';
// import PayMethodList from './components/screens/page/PayMethodList';
// import PayMethodCreate from './components/screens/page/PayMethodCreate';
// import PayMethodEdit from './components/screens/page/PayMethodEdit';
// import PayMethodShow from './components/screens/page/PayMethodShow';

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />

                {/* <Route path="/user" element={<UserManager />} />
                <Route path="/connect" element={<ConnectWhatsapp />} />
                <Route path="/whatsapp" element={<WhatsappManager />} />

                <Route path="/contact" element={<Contacts />} />
                <Route path="/template" element={<TemplateManager />} /> */}

                {/* <Route path="/profile" element={<ProfileManager />} />
                <Route path="/payroll" element={<PayrollManager />} />
                <Route path="/payment" element={<PaymentManager />} />
                <Route path="/connectHRM" element={<ConnectHRM />} />

                <Route exact path="/bank"  element={<BankList/>} />
                <Route path="/bank/create"  element={<BankCreate/>} />
                <Route path="/bank/edit/:id"  element={<BankEdit/>} />
                <Route path="/bank/show/:id"  element={<BankShow/>} />

                <Route exact path="/department"  element={<DepartmentList/>} />
                <Route path="/department/create"  element={<DepartmentCreate/>} />
                <Route path="/department/edit/:id"  element={<DepartmentEdit/>} />
                <Route path="/department/show/:id"  element={<DepartmentShow/>} />

                <Route exact path="/jobtitle"  element={<JobTitleList/>} />
                <Route path="/jobtitle/create"  element={<JobTitleCreate/>} />
                <Route path="/jobtitle/edit/:id"  element={<JobTitleEdit/>} />
                <Route path="/jobtitle/show/:id"  element={<JobTitleShow/>} />

                <Route exact path="/position"  element={<PositionList/>} />
                <Route path="/position/create"  element={<PositionCreate/>} />
                <Route path="/position/edit/:id"  element={<PositionEdit/>} />
                <Route path="/position/show/:id"  element={<PositionShow/>} />

                <Route exact path="/contracttype/"  element={<ContractTypeList/>} />
                <Route path="/contracttype/create"  element={<ContractTypeCreate/>} />
                <Route path="/contracttype/edit/:id"  element={<ContractTypeEdit/>} />
                <Route path="/contracttype/show/:id"  element={<ContractTypeShow/>} />

                <Route exact path="/bonustype/"  element={<BonusTypeList/>} />
                <Route path="/bonustype/create"  element={<BonusTypeCreate/>} />
                <Route path="/bonustype/edit/:id"  element={<BonusTypeEdit/>} />
                <Route path="/bonustype/show/:id"  element={<BonusTypeShow/>} />

                <Route exact path="/paymethod/"  element={<PayMethodList/>} />
                <Route path="/paymethod/create"  element={<PayMethodCreate/>} />
                <Route path="/paymethod/edit/:id"  element={<PayMethodEdit/>} />
                <Route path="/paymethod/show/:id"  element={<PayMethodShow/>} /> */}

            </Routes>

            {/* <Footer /> */}
        </>
    );
}

export default App;
