import React from 'react'
import Global from '../../libs/Global'

const Dashboard = (props) => {
    const {auth} = props

    return auth && auth.user.name ? (
        <div className="text-center text-light p-3" style={{backgroundColor: "#282A35"}}>
            Hi {auth.user.name}, <br/>
            Your role is {auth.user.type}. <br/>
            You were login successfully!
        </div>
    ) : (
        localStorage.clear()
    )
}

export default Dashboard
